<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white p-6 lg:p-12 lg:border lg:rounded-xl lg:my-6">
        <header class="text-center">
          <LinkIcon class="p-3 h-12 w-12 mx-auto mb-3 text-primary-600 bg-primary-100 rounded-xl" />
          <h2 class="font-bold text-xl leading-9 mb-3">
            Você gerou um link de pagamento no valor de <span class="whitespace-nowrap">
              {{ newPaymentLink.value | moneyFilter }}
            </span>
          </h2>
          <p class="text-gray-500">
            O link foi enviado via SMS<span v-if="newPaymentLink.customerEmail"> e email</span>.
            Você também pode copiar o link para compartilhar por outros meios.
          </p>
        </header>
        <div class="mt-6">
          <div class="relative whitespace-nowrap overflow-hidden p-3 bg-gray-100 rounded-md mb-3">
            {{ newPaymentLink.link }}
            <div class="absolute top-0 right-0 w-9 h-full bg-gradient-to-l from-gray-100"></div>
          </div>
          <div class="flex flex-col gap-5">
            <v-popover
              placement='top'
              :offset="8"
              :open="showTooltip"
            >
              <Button @click="copyPaymentLink" class="w-full" kind="primary">
                  <DuplicateIcon size="24"/>
                  <span>Copiar link</span>
              </Button>

              <p slot="popover">Link copiado</p>
            </v-popover>
            <Button link @click="goToPaymentLinkMenu">
              Ir para listagem de links
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DuplicateIcon, LinkIcon } from '@vue-hero-icons/outline';
import { Button } from '../../../components';
import stringMixin from '../../../mixins/string';

export default {
  name: 'success-payment-link',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  components: {
    DuplicateIcon,
    Button,
    LinkIcon,
  },
  data: () => ({
    showTooltip: false,
  }),
  mixins: [stringMixin],
  computed: {
    ...mapState('paymentLink', ['newPaymentLink']),
  },
  methods: {
    ...mapActions('paymentLink', ['resetPaymentLinkCreation']),
    async goToPaymentLinkMenu() {
      this.resetPaymentLinkCreation();
      await this.$router.replace({ name: 'payment-link-list' });
    },
    copyPaymentLink() {
      this.showTooltip = true;
      navigator.clipboard.writeText(this.newPaymentLink.link);

      setTimeout(() => {
        if (this.showTooltip) this.showTooltip = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-popover ::v-deep .trigger{
    width: 100% !important;
  }
</style>
